import './footer.css'

/**
 * Props for a footer
 */
interface FooterProps {

}

export default function Footer(props: FooterProps) {
    return <div className="footer">
        Jacob Klimczak | 2022
    </div>;
}